<template>
	<div class="single-view number-converter">
		<div class="tool-box">
			<h1>{{ ii('NUMBER_CONVERTER') }}</h1>
			<div>
				<div class="tool-input-box">
					<converter-form ref="cf" :items="getConverterItems()"></converter-form>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

				<div class="examples-box">
					<h3>{{ ii('INPUT_EXAMPLES') }}:</h3>
					<ul>
						<li v-for="(example, key) in examples">
							<span class="link" @click="onExampleClick(example, key)">
								{{ ii(converter[key].label || '') }}: {{ example }}
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				bin: '',
				oct: '',
				dec: '',
				hex: '',
				converter: {
					bin: {
						label: 'BINARY',
						regexp: /[^0-1\-]/,
						error: null,
						type: 2
					},
					oct: {
						label: 'OCTAL',
						regexp: /[^0-7\-]/,
						error: null,
						type: 8
					},
					dec: {
						label: 'DECIMAL',
						regexp: /[^0-9\-]/,
						error: null,
						type: 10
					},
					hex: {
						label: 'HEXADECIMAL',
						regexp: /[^0-9A-Fa-f\-]/,
						error: null,
						type: 16
					},
				},
				examples: {
					bin: '101',
					oct: '15',
					dec: '1159',
					hex: '1A'
				}
			};
		},
		methods: {
			getConverterItems() {
				let items = [];
				for (let key in this.converter) {
					let item = this.converter[key];
					items.push({
						key,
						ref: key,
						icon: 'hashtag',
						label: this.ii(item.label),
						error: item.error,
						onInput: () => this.updateQueryByValue(key)
					});
				}

				return items;
			},
			updateValues(key) {
				for (let prop in this.converter) {
					let error = null;

					if (this.isInvalidValue(key)) {
						if (key === prop) error = this.ii('INVALID_VALUE');
					} else if (key) {
						let from = this.converter[key].type || '';
						let to = this.converter[prop].type || '';
						this[prop] = this.convertNumber(this[key], from, to);
					} else {
						this[prop] = '';
					}

					this.$set(this.converter[prop], 'error', error);
					this.$nextTick(() => {
						if (prop === key) this.$refs.cf.$refs[key][0].focus();
					});
				}
			},
			convertNumber(number, from, to) {
				if (!number) return '';
				return parseInt(number, from).toString(to).toUpperCase();
			},
			isInvalidValue(key) {
				if (!key || !this[key] || !this.converter[key]) return false;
				if (this[key].indexOf('-') > 0) return true;

				let type = this.converter[key].type;
				let number = parseInt(this[key], type);
				if (isNaN(number)) return true;

				let symbols = this[key].match(/\-/g);
				if (symbols && symbols.length > 1) return true;

				return this.converter[key].regexp.test(this[key]);
			},
			updateQueryByValue(key) {
				clearTimeout(this.debounce);
				this.debounce = setTimeout(() => {
					let value = this[key] || '';
					this.app.query = {from: value ? key : '', val: value};
					this.refreshQuery();
				}, 300);
				this.updateValues(key);
			},
			updateValueByQuery() {
				let key = this.app.query.from || '';
				let value = this.app.query.val || '';

				if (key) {
					if (this[key] === value) return;
					this[key] = value;
				}

				this.updateValues(key);
			},
			onExampleClick(value, key) {
				this[key] = value;
				this.updateQueryByValue(key);
			}
		},
		watch: {
			'app.query'() {
				this.updateValueByQuery();
			}
		},
		async mounted() {
			this.updateValueByQuery();
		}
	}
</script>